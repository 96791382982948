body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    font-family: Arial, sans-serif;

    background-color: #f4f4f4;
}
.scrollable {
    max-height: 300px; /* Set max height for scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 0px solid #ccc;
    background-color: #fff;
    /* padding:5px; */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}
.card {
    border: 1px solid #007bff;
    border-radius: 5px;
    margin-bottom: 0px;
    padding: 10px;
}
.row {
    display: flex;
    justify-content: space-between;
}
